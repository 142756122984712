import * as React from "react"

import SectionTitle from "../components/titleBlock"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import { gsap } from "gsap";
import CallUs from "../components/callUsStrap"


const WhyWorkWithUs = ({ }) => {
    
    
   
    return (
        <div className="container-fluid bg-light py-5 whatCanWeDo">
           
            <div className="container py-5 ">
                <div className="row">
                    <div className="col-12 col-md-6 d-flex align-items-center"
                    data-sal-duration="500"
                    data-sal="slide-up"
                    data-sal-delay="200"
                    data-sal-easing="ease"
                    >
                        <div>
                        <SectionTitle title="What can we do?"></SectionTitle>
                        <ul className="fs-1 fw-light list-unstyled">
                            <li onMouseOver={() =>  gsap.to('.servicesSlider', {y: 0, duration: 0.3, ease: "expo"}) } >
                                <Link to="/uniforms" className="" href="#">Uniforms</Link>
                            </li>
                            <li onMouseOver={() =>  gsap.to('.servicesSlider', {y: -(gsap.getProperty('.serviceWrapper', "height") * 1)+'px' , duration: 0.3, ease: "expo"}) } >
                                <Link to="/workwear" className="" href="#">Workwear</Link>
                            </li >
                            <li onMouseOver={() =>  gsap.to('.servicesSlider', {y: -(gsap.getProperty('.serviceWrapper', "height") * 2)+'px' , duration: 0.3, ease: "expo"}) } >
                                <Link to="/promo-clothing" className="" href="#">Promotional Clothing</Link>
                            </li>
                           <li onMouseOver={() =>  gsap.to('.servicesSlider', {y: -(gsap.getProperty('.serviceWrapper', "height") * 3)+'px' , duration: 0.3, ease: "expo"}) } >
                                <Link to="/promo-items" className="" href="#">Promotional items</Link>
                            </li>
                        </ul>
                        </div>
                       
                    </div>
                    <div className="col-12 col-md-6 mr-n5">
                        <div className="servicesColumn"
                        data-sal-duration="500"
                        data-sal="slide-left"
                        data-sal-delay="400"
                            data-sal-easing="ease"
                        >
                            <div className="servicesSlider" >
                                <div className="serviceWrapper position-relative">
                                    <div className="container position-absolute bottom-0 start-50 translate-middle-x text-light p-3 p-md-5 z1">
                                        <div className="row">
                                            <div className="col-12 ">
                                                <h4 className="fs-3 fw-light">Interested in Uniforms?</h4>
                                                <div className="row mt-3">
                                                    
                                                    <div className="col-12 col-md-5">
                                                        <div className="d-grid ">
                                                            <Link to="/contact" className="btn btn-lg btn-light rounded-0" href="/contact">Find out more</Link>
                                                        </div>
                                                    </div>
                                                    <div className="col align-middle d-flex align-items-center mt-3 mt-md-0">
                                                        <CallUs
                                                            accent={'primary'}
                                                        />
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <StaticImage 
                                        src="../images/headers/compressed/TKing_Uniforms2.jpg"
                                        quality={85} 
                                        formats={["AUTO", "WEBP"]}
                                        alt="TKing uniforms"
                                        placeholder="blurred"
                                        objectFit="cover"
                                        layout="fullWidth"
                                        className=" d-none d-md-block h-100"
                                    />
                                    <StaticImage 
                                        src="../images/headers/compressed/TKing_Uniforms.jpg"
                                        quality={85}
                                        formats={["AUTO", "WEBP"]}
                                        alt="TKing uniforms"
                                        placeholder="blurred"
                                        objectFit="cover"
                                        layout="fixed"
                                        height={400}
                                        width={400}
                                        className="d-block d-md-none w-100"
                                    />
                                    
                                </div>

                                <div className="serviceWrapper position-relative">
                                    <div className="container position-absolute bottom-0 start-50 translate-middle-x text-light p-3 p-md-5 z1">
                                        <div className="row">
                                            <div className="col-12 ">
                                                <h4 className="fs-3 fw-light">Interested in Workwear?</h4>
                                                <div className="row mt-3">
                                                    
                                                    <div className="col-12 col-md-5">
                                                        <div className="d-grid ">
                                                            <Link to="/contact" className="btn btn-lg btn-light rounded-0" href="/contact">Find out more</Link>
                                                        </div>
                                                    </div>
                                                    <div className="col align-middle d-flex align-items-center mt-3 mt-md-0"><CallUs
                                                            accent={'primary'}
                                                        /> </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <StaticImage 
                                        src="../images/headers/compressed/TKing-workwear.jpg"
                                        quality={85}
                                        formats={["AUTO", "WEBP"]}
                                        alt="Workwear"
                                        placeholder="blurred"
                                        objectFit="cover"
                                        layout="fullWidth"
                                        className=" d-none d-md-block h-100"
                                    />
                                    <StaticImage 
                                        src="../images/headers/compressed/TKing-workwear.jpg"
                                        quality={85}
                                        formats={["AUTO", "WEBP"]}
                                        alt="Workwear"
                                        placeholder="blurred"
                                        objectFit="cover"
                                        layout="fixed"
                                        height={400}
                                        width={400}
                                        className="d-block d-md-none w-100"
                                    />
                                </div>

                                <div className="serviceWrapper position-relative">
                                    <div className="container position-absolute bottom-0 start-50 translate-middle-x text-light p-3 p-md-5 z1">
                                        <div className="row">
                                            <div className="col-12 ">
                                                <h4 className="fs-3 fw-light">Interested in Promotional Clothing?</h4>
                                                <div className="row mt-3">
                                                    
                                                    <div className="col-12 col-md-5">
                                                        <div className="d-grid ">
                                                            <Link to="/contact" className="btn btn-lg btn-light rounded-0" href="/contact">Find out more</Link>
                                                        </div>
                                                    </div>
                                                    <div className="col align-middle d-flex align-items-center mt-3 mt-md-0"><CallUs
                                                            accent={'primary'}
                                                        /> </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <StaticImage 
                                        src="../images/headers/compressed/TKing_promo-clothing.jpg"
                                        quality={85}
                                        formats={["AUTO", "WEBP"]}
                                        alt="TKing Promotional Clothing"
                                        placeholder="blurred"
                                        objectFit="cover"
                                        layout="fullWidth"
                                        className=" d-none d-md-block h-100"
                                    />
                                    <StaticImage 
                                        src="../images/headers/compressed/TKing_promo-clothing.jpg"
                                        quality={85}
                                        formats={["AUTO", "WEBP"]}
                                        alt="TKing Promotional Clothing"
                                        placeholder="blurred"
                                        objectFit="cover"
                                        layout="fixed"
                                        height={400}
                                        width={400}
                                        className="d-block d-md-none w-100"
                                    />
                                </div>

                                <div className="serviceWrapper position-relative">
                                    <div className="container position-absolute bottom-0 start-50 translate-middle-x text-light p-3 p-md-5 z1">
                                        <div className="row">
                                            <div className="col-12 ">
                                                <h4 className="fs-3 fw-light">Interested in Promotional Items?</h4>
                                                <div className="row mt-3">
                                                    
                                                    <div className="col-12 col-md-5">
                                                        <div className="d-grid ">
                                                            <Link to="/contact" className="btn btn-lg btn-light rounded-0" href="/contact">Find out more</Link>
                                                        </div>
                                                    </div>
                                                    <div className="col align-middle d-flex align-items-center mt-3 mt-md-0"><CallUs
                                                            accent={'primary'}
                                                        /> </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <StaticImage 
                                            src="../images/headers/compressed/TKing-promo-items-v2.jpg"
                                            quality={85}
                                            formats={["AUTO", "WEBP"]}
                                            alt="TKing Promotional items"
                                            placeholder="blurred"
                                            objectFit="cover"
                                            layout="fullWidth"
                                            className=" d-none d-md-block h-100"
                                        />
                                        <StaticImage 
                                            src="../images/headers/compressed/TKing-promo-items-v2.jpg"
                                            quality={85}
                                            formats={["AUTO", "WEBP"]}
                                            alt="TKing Promotional items"
                                            placeholder="blurred"
                                            objectFit="cover"
                                            layout="fixed"
                                            height={400}
                                            width={400}
                                            className="d-block d-md-none w-100"
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyWorkWithUs
