import * as React from "react"
import { useRef, useEffect } from "react";

import { StaticImage } from "gatsby-plugin-image"

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "gatsby"
import SectionTitle from "../components/titleBlock"

gsap.registerPlugin(ScrollTrigger);

const WhyWorkWithUs = ({  }) => {
    
    const ref = useRef(null);

    useEffect(() => {
        const element = ref.current;
          let uniform_scroller = gsap.timeline({
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
                trigger:element,
                start: "top bottom",
                end:  "bottom top",
                scrub: true, 
              }
              
        });

        uniform_scroller
            .fromTo('.image_1',
                {  x:'10vw',y:'-100px' },
                { x: '10vw', y: 0 },0)
            .fromTo('.image_2',
                    { y:'20vh' },
                    {  y: 0 },0)
            .fromTo('.image_3',
                    {  x:'-5vw',y:'50vh' },
                    { x:'-5vw',y: '-5vh' },0)
           
         return () => {
            // Cleanup here, kill your ScrollTrigger instances here
            ScrollTrigger.getAll().forEach(t => t.kill());
        };
        
    }, []);
    return (
        <div className="content-fluid bg-primary whyWorkWithUs" ref={ref}>
            <div className="container py-5" 
         
        >
            <div className="row">
                    <div
                        className="col-12 col-md-4  d-flex flex-column justify-content-center"
                        data-sal="slide-up"
                        data-sal-duration="500"
                    > 
                    <SectionTitle title="Why work with us?"></SectionTitle>
                    <h3 className="fs-2 fw-light">
                        Our team are friendly and knowledgeable, with a genuine care about the story behind every order. 
                    </h3>
                </div>
                    <div className="col-12 col-md-8 ">
                        <StaticImage
                            src="../images/TKing_27.jpg"
                            quality={85}
                            width={700}
                            height={600}
                            formats={["AUTO", "WEBP"]}
                            alt="Looking at swatches"
                            placeholder="blurred"
                            className="d-none d-md-block image_1"
                        />
                        <StaticImage 
                            src="../images/TKing_27.jpg"
                            quality={85}
                            formats={["AUTO", "WEBP"]}
                            alt="About us"
                            placeholder="blurred"
                            layout="fixed"
                            height={300}
                            width={600}
                            className="d-block d-md-none my-3 w-100"
                        />
                </div>
                </div>
                
                <div className="row">
                    <div className="d-none d-md-block  col-12 col-md-4 ">
                        <StaticImage
                            src="../images/TKIng-14.jpg"
                            quality={85}
                            width={400}
                            height={500}
                            formats={["AUTO", "WEBP"]}
                            alt="TKing family"
                            placeholder="blurred"
                            objectFit="contain"
                            objectPosition="right"
                            className="image_3"
                        />
                    </div>
                     <div className="d-none d-md-block  col-12 col-md-4 ">
                        <StaticImage
                            src="../images/TKIng-13.jpg"
                            quality={85}
                            width={300}
                            formats={["AUTO", "WEBP"]}
                            alt="Colour selection"
                            placeholder="blurred"
                            objectFit="contain"
                            className="image_2"
                        />
                    </div>
                    <div
                        className="col-12 col-md-4 d-flex flex-column justify-content-center"
                        data-sal="slide-up"
                        data-sal-duration="500"
                    > 
                    
                    <h3 className="fs-2 fw-light mb-3 mb-md-4 ">
                        It’s your name & our reputation. At TKing Associates, the customer is always king.
                        </h3>
                        <Link to="/contact" className="btn btn-lg btn-secondary rounded-0" href="/contact">Get in touch</Link>
                </div>
                
            </div>
        </div>

        </div>
        
    )
}

export default WhyWorkWithUs
