import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"

import { graphql } from 'gatsby'



import HomeHeroScrollTrigger from "../components/homeHeroScrollTrigger"


import Testimonials from "../components/testimonials"
import WhyWorkWithUs from "../components/whyWorkWithUs"
import FullWidthVideo from "../components/fullWidthVideoBlock"
import WhatCanWeDo from "../components/whatCanWeDo"
import StoryBlock from "../components/titleBlockPages"

import ContactForm from "../components/footerContactBlock"
import SectionTitle from "../components/titleBlock"
import CallUs from "../components/callUsStrap"
import UniformCityLogo from "../images/uniformcity.svg"
import { Link } from "gatsby"



const IndexPage = ({ data }) => {
  ///page details
  const pageDataEdges = data.allPagehomeJson.edges[0].node;
  const testimonialData = data.allTestimonialshomeJson.edges;
  const nodes = testimonialData.map((item) => { 
    return item.node;
  })


  return (
    <Layout>
      <Seo
        title="Personalised T Shirt Printing Milton Keynes | Work, School & Safety Wear"
        description="Embroidery and personalised T-Shirt printing Milton Keynes and Buckinghamshire. Customised Clothing, School, Safety and Work Wear. Family owned"
      />
      <div
        style={{
                overflow: 'hidden',
            }}
      >
        <HomeHeroScrollTrigger />
        <Testimonials
          bg={"light"}
          sectionCopy={'More than just pens & personalised t shirt printing. We’re experts in brand expression & lasting impressions.'}
          nodes={nodes}
        />
        <WhyWorkWithUs />
        <FullWidthVideo
          url="https://tkingfiles.fra1.digitaloceanspaces.com/2022_videos/TKING_embroidery_web_banner_video_v01.mp4"
          copy="From custom printed t shirts, baseball caps and hoodies to notebooks and tote bags. If we can source it, we can print or embroider it."
        />
        <WhatCanWeDo />
        <div className="container">
        <div className="row mt-5">
          <div className="col-12 col-md-6">
              <SectionTitle title="Uniform management"></SectionTitle>
              <p className="fw-light fs-2 mb-5">
                Are you searching for someone to manage all your uniform requirements? 
              </p>
          </div>
         
        </div>
        <div className="row mb-5">
          <div className="col-12 col-md-6 ">
              <p className="fw-light fs-4 mb-4">
                Look no further…
              </p>
          </div>
          <div className="col-12 col-md-6 d-flex flex-column justify-content-end">
            <div>
             
            </div>
          </div>
        </div>
        </div>
        <div className="container">

        <div
          className="row mb-5"
          
        >
          <div
            className="col-12 col-md-6 "
            data-sal-duration="500"
            data-sal="slide-right"
            data-sal-delay="200"
            data-sal-easing="ease"
          >
              <video id="theVideo" className="w-100" preload="yes"  controls autoPlay muted    >
                <source src={'https://tkingfiles.fra1.digitaloceanspaces.com/2022_videos/uniform_city_how_to_use_the_web_portal_v01.mp4'} type="video/mp4" />
            </video>
          </div>
          <div
            className="col-12 col-md-6  d-flex flex-column justify-content-start"
            data-sal-duration="500"
            data-sal="slide-up"
            data-sal-delay="400"
            data-sal-easing="ease"
          >
             <p className="fw-light fs-4 mb-3">
                Our bespoke uniform & workwear  system is tailored to your product requirements and identity.
              </p>
              <p className="fw-light fs-4 mt-3 mb-3 mt-md-0">
              <img
                style={{
                  height:'1.5rem',
                  }}
                src={UniformCityLogo}
                alt="Uniformcity Logo" /> your destination for seamless, flexible uniform management brought to you by TKing Associates.
            </p>
            <div className="row">
              <div className="col-12 col-md-6 my-3 my-md-0">
                <div className="d-grid gap-2">
                  <Link to="/contact" className="btn btn-lg btn-primary rounded-0" href="/contact">Get in touch</Link>
                  </div>
              </div>

              <div className="col  align-middle d-flex align-items-center">
                  <CallUs
                      accent={'secondary'}
                  />
              </div>
            </div>
          </div>
          </div>
          </div>
        <StoryBlock
          sectionTitle = {'Sustainability'}
          pageTitle={pageDataEdges.storyBlockTitle}
          pageSubTitle={pageDataEdges.storyBlockSubTitle}
          pageTitleBody={pageDataEdges.storyBlockBody}
          img={pageDataEdges.storyBlockImg}
        />

          <ContactForm/>
        </div>
    </Layout>
  )
}
export const query = graphql`
  query MyQuery {

    allPagehomeJson {
      edges {
        node {
          storyBlockBody
          storyBlockTitle
          storyBlockSubTitle
          storyBlockImg {
            childImageSharp {
              gatsbyImageData(
                width: 1100
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }



    allTestimonialshomeJson {
      edges {
        node {
          name
          copy
            img {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }

  
}`
export default IndexPage
